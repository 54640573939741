<template>
  <v-app>
    <core-app-bar />

    <core-drawer />

    <core-reset-password-view />

    <core-footer />

  </v-app>
</template>

<script>
export default {
  name: 'ResetPassword',

  components: {
    CoreAppBar: () => import('../components/core/AppBar'),
    CoreDrawer: () => import('../components/core/Drawer'),
    CoreResetPasswordView: () => import('../components/core/ResetPasswordView'),
    CoreFooter: () => import('../components/core/Footer'),
  },

  data: () => ({
    expandOnHover: false,
  }),
}
</script>
